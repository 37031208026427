import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Button } from 'reactstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { selectWorkspacesStatus } from 'slices/workspacesSlice'
import { selectWorksLiteStatus } from 'slices/worksLiteSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant, selectTenantsStatus } from 'slices/tenantsSlice'

import { NavMenu, BadgeLabel, BadgeButton, Table, MonthSelect, CustomButton } from 'components/common'
import type { BadgeItem, TableHeaderType, TableCellType } from 'components/common/types'

import styles from './Schedules.module.scss'

type Props = {
  isPast: boolean
  isReadOnly: boolean
  tableHeader: TableHeaderType[]
  tableData: TableCellType[][]
  badges: BadgeItem[]
  selectedBadges: number[]
  selectedMonth: string
  onBadgesChange: (list: number[]) => void
  setIsPast: (flag: boolean) => void
  onMonthChange: (month: string) => void
  isMoreLoadButtonClicked: boolean
  setIsMoreLoadButtonClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const SchedulesCommon: React.FC<Props> = props => {
  const {
    isPast,
    isReadOnly,
    tableHeader,
    tableData,
    badges,
    selectedBadges,
    selectedMonth,
    onBadgesChange,
    setIsPast,
    onMonthChange,
    isMoreLoadButtonClicked,
    setIsMoreLoadButtonClicked,
  } = props

  const { workspaceId } = useParams<'workspaceId'>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { works } = useSelector(selectWorksLiteStatus, shallowEqual)
  const { user } = useSelector(selectSessionStatus, shallowEqual)

  const menuItems = React.useMemo(
    () =>
      _.chain(workspaces)
        .map(w => ({ type: `/schedules/${w.workspaceId}`, label: w.name }))
        .sortBy('label')
        .value(),
    [workspaces]
  )

  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])
  const { tenants } = useSelector(selectTenantsStatus, shallowEqual)
  const tenantStartMonth = React.useMemo(() => {
    // 作業計画では利用開始日をステータスがactiveの時ではなく、
    // テナントが作成された時とするため、startedAtではなくcreatedAtを使用する
    const createdAt = tenants[0]?.createdAt
    return moment(createdAt).format('YYYY-MM')
  }, [tenants])

  React.useEffect(() => {
    if (!workspaceId && menuItems.length > 0) {
      navigate(menuItems[0].type, { replace: true })
    }
  }, [navigate, menuItems, workspaceId])

  const onRowClick = (index: number) => {
    const date = tableData[index][0].value as string
    const formatedDate = date.replace(/(\d{4})\/(\d{2})\/(\d{2}).*/g, '$1-$2-$3')
    const workId = works.find(w => w.date === formatedDate)?.workId
    navigate(`/schedules/${workspaceId}/${workId}`)
  }

  const handleNavMenuClick = (type: string) => {
    setIsMoreLoadButtonClicked(false)
    navigate(type)
  }
  const selector = React.useMemo(() => {
    const menu = menuItems.find(item => item.type === pathname)
    return menu ? menu.label : ''
  }, [menuItems, pathname])

  const handleLoadMoreClick = () => {
    setIsMoreLoadButtonClicked(true)
  }

  const moveToWorkspaceEdit = () => {
    navigate(`/workspaces/${workspaceId}`)
  }

  const onSchedulesButtonClick = () => {
    setIsMoreLoadButtonClicked(false)
    setIsPast(!isPast)
  }

  return (
    <NavMenu type={pathname} items={menuItems} onNavMenuClick={handleNavMenuClick}>
      <div className="mt-3 mx-3">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex">
            <div className="font-x-large fw-bold">{isPast ? '過去の' : ''}作業計画一覧</div>
            <div className="px-2 align-self-center">
              <BadgeLabel label={selector} />
            </div>
          </div>
          <div className="d-flex">
            {!isPast && (
              <CustomButton outline icon="edit" disabled={isReadOnly} onClick={moveToWorkspaceEdit}>
                ワークスペースの設定
              </CustomButton>
            )}
            {isPast && (
              <div className="bg-white rounded">
                <MonthSelect
                  start={tenantStartMonth}
                  end={moment().format('YYYY-MM')}
                  selectedMonth={selectedMonth}
                  onChange={onMonthChange}
                />
              </div>
            )}
            <CustomButton outline className="ms-2" onClick={onSchedulesButtonClick}>
              {isPast ? '作業計画一覧' : '過去の計画'}
            </CustomButton>
          </div>
        </div>

        <div className="font-x-small text-muted my-2">リストの表示項目の編集</div>
        <div className="d-flex row my-2">
          <BadgeButton items={badges} selected={selectedBadges} onChange={onBadgesChange} name="work-plan-list-badge" />
        </div>

        <div className="d-flex bg-white mt-3 mw-100">
          <div className={styles.dateTable}>
            <Table
              header={tableHeader.slice(0, 3)}
              body={tableData.map(data => data.slice(0, 3))}
              onRowClick={onRowClick}
            />
          </div>
          <div className={styles.scTable}>
            <Table
              header={tableHeader.slice(3)}
              body={tableData.map(data => data.slice(3))}
              name="work-plan-list-table"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center my-2">
          {!isMoreLoadButtonClicked && !isPast && (
            <Button outline onClick={handleLoadMoreClick}>
              さらに読み込む
            </Button>
          )}
        </div>
      </div>
    </NavMenu>
  )
}

export default SchedulesCommon
