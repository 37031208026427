import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, ButtonGroup } from 'reactstrap'

import type { GroupResponse } from 'api/groups'
import { Role } from 'api/users'
import type { UpdateWorkSchedule, PerformanceRateData } from 'api/works'

import { showError, showSuccess, showWarning } from 'slices/notificationSlice'
import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getTenant, selectTenantsStatus } from 'slices/tenantsSlice'
import {
  clearErrorMessage,
  getWork,
  selectWorksStatus,
  getWorkForCompare,
  updatePerformanceRatesAndWork,
  getWorkByDate,
} from 'slices/worksSlice'
import { getSkillList } from 'slices/skillsSlice'

import {
  BadgeLabel,
  NavMenu,
  SubmitFooter,
  MoveDropdown,
  CustomButton,
  AssignedNumberTable,
  IconButton,
  SidebarButton,
  DateChangeButton,
  DropdownButton,
} from 'components/common'
import type { ColorType, EditSchedule, TableItemType, ItemScheduleType } from 'components/common/types'
import {
  getEditSchedules,
  getOriginalSchedulesFromWork,
  UNSELECTED_SCHEDULE_TYPE_ID,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  MAGIQANNEAL_APPLICATION_ID,
  hasOverlappedSchedule,
  timeOverlapped,
} from 'components/common/utils'
import ImportOptimizedDialog from 'components/Schedules/Optimization/ImportOptimizedDialog'
import OptimizationDialog from 'components/Schedules/Optimization/OptimizationDialog'
import TargetValuesUpdate from 'components/Schedules/TargetValuesUpdate'
import ImportTemplateDialog from 'components/Template/ImportTemplateDialog'

import useBusinessTime from 'hooks/useBusinessTime'

import PerformanceRates from '../PerformanceRates/PerformanceRates'

import { AssignToWorkTableContext } from './context'
import WorkPlanCard from './WorkPlanCard'
import WorkPlanFilter from './WorkPlanFilter'
import AssignToWorkTable from './AssignToWorkTable'
import DeleteSchedules from './DeleteSchedules'
import ShiftAdjustment from './ShiftAdjustment'

import styles from './WorkPlan.module.scss'

import type { ScheduleTypeGroup } from './ScheduleTypeSelector'
import type { GraphRow, CompareGraph } from './WorkPlanCard'
import type { SelectedScheduleType } from './context'
import type { DisplayPerformanceRate } from '../PerformanceRates/PerformanceRates'
import type { WorkPlanSchedulesType, EditGroupsType } from '../types'

const hasUnselectedSchedule = (schedules: EditSchedule[]) =>
  schedules.some(schedule => schedule.scheduleTypeId === UNSELECTED_SCHEDULE_TYPE_ID)

type WorkPlanCardItem = {
  label: string
  color: ColorType
  unit: string | null
  targetValue: number
  planValue: number
  graphRows: GraphRow[]
  defaultPerformanceIndex: number
}

type SeparateScheduleType = { scheduleTypeId: number; time: string; planValue: number }

const separateScheduleByQuarterHours = (start: string, duration: number) => {
  const startMoment = moment(start)
  // eslint-disable-next-line no-shadow
  return [...Array(Math.round(duration / 900))].map((_, i) => ({
    time: startMoment
      .clone()
      .add(900 * i, 'seconds')
      .toISOString(),
    duration: 900,
  }))
}

const calcPlanValue = (
  performanceRate: { time: string; value: number | null }[],
  performanceIndex: number,
  startAt: string,
  duration: number
) => {
  const calcScheduleByQuarterHours = separateScheduleByQuarterHours(startAt, duration).map(separateSchedule => {
    const targetPerformanceRate = performanceRate.find(pr => pr.time === separateSchedule.time)?.value ?? 1
    const planValue = (separateSchedule.duration * performanceIndex * targetPerformanceRate) / 3600 // durationから予測値を計算
    return {
      time: separateSchedule.time,
      planValue,
    }
  })
  const groupByHours = _.groupBy(calcScheduleByQuarterHours, o => new Date(o.time).getHours())
  return _.keys(groupByHours).map(hours => ({
    time: new Date(new Date(groupByHours[hours][0].time).setMinutes(0)).toISOString(),
    planValue: _.sumBy(groupByHours[hours], 'planValue'),
  }))
}

// 生産性調整の時間が重なっていないかチェックする
const hasOverlappedPerformanceRate = (data: PerformanceRateData[]) => {
  return data.some((controlData, index) => {
    return data.slice(index + 1).some(targetData => {
      return timeOverlapped(controlData.startTime, controlData.duration, targetData.startTime, targetData.duration)
    })
  })
}

const WorkPlan: React.FC = () => {
  const params = useParams<'workspaceId' | 'workId'>()
  const { workspaceId, workId } = React.useMemo(
    () => ({
      workspaceId: Number(params.workspaceId),
      workId: Number(params.workId),
    }),
    [params]
  )
  const [openImportOptimized, setOpenImportOptimized] = React.useState(false)
  const [openOptimization, setOpenOptimization] = React.useState(false)
  const [openTargetValuesUpdate, setOpenTargetValuesUpdate] = React.useState(false)
  const [openDeleteSchedules, setOpenDeleteSchedules] = React.useState(false)
  const [openTemplate, setOpenTemplate] = React.useState(false)
  const [openShiftAdjustment, setOpenShiftAdjustment] = React.useState(false)
  const [initGroups, setInitGroups] = React.useState<EditGroupsType[]>([])
  const [editGroups, setEditGroups] = React.useState<EditGroupsType[]>([])
  const [selectedWorker, setSelectedWorker] = React.useState<number[]>([])
  const [displayRate, setDisplayRate] = React.useState<DisplayPerformanceRate[]>([])
  const [initDisplayRate, setInitDisplayRate] = React.useState<DisplayPerformanceRate[]>([])
  const [shouldResetFilters, setShouldResetFilters] = React.useState(false)
  // 再レンダリングにより、保存中の画面から変更したスケジュールが変更前の状態で表示されるためuseStateではなくuseRefを使う
  const separateSchedulesBeforeSaving = React.useRef<SeparateScheduleType[]>([])
  const [submitted, setSubmitted] = React.useState(false)
  const [viewWorkTable, setViewWorkTable] = React.useState<
    'workTable' | 'performanceRateTable' | 'assignedNumberTable'
  >('workTable')
  const [openGroups, setOpenGroups] = React.useState<string[]>([])
  const [workTablePosition, setWorkTablePosition] = React.useState<{ top?: number; left?: number }>({})
  const [numberTablePosition, setNumberTablePosition] = React.useState<{ top?: number; left?: number }>({})
  const [performanceRatesTablePosition, setPerformanceRatesTablePosition] = React.useState<{
    top?: number
    left?: number
  }>({})
  const [shiftKeyDown, setShiftKeyDown] = React.useState(false)
  const [selectedSchedules, setSelectedSchedules] = React.useState<SelectedScheduleType[]>([])
  const [isOpenSidebar, setOpenSidebar] = React.useState(true)
  const [isOpenFilter, setIsOpenFilter] = React.useState(false)
  window.document.onkeydown = event => setShiftKeyDown(event.shiftKey)
  window.document.onkeyup = event => setShiftKeyDown(event.shiftKey)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { works, worksToCompare, isRequesting, errorMessage, scheduleTruncated } = useSelector(
    selectWorksStatus,
    shallowEqual
  )
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const work = React.useMemo(() => works.find(w => w.workId === workId), [works, workId])

  React.useEffect(() => {
    dispatch(getWorkspaceList())
    dispatch(getSkillList())
  }, [dispatch])

  React.useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWork(workspaceId, workId))
  }, [dispatch, workspaceId, workId])

  const { user } = useSelector(selectSessionStatus, shallowEqual)
  React.useEffect(() => {
    dispatch(getTenant(user.tenants[0].tenantId))
  }, [dispatch, user])
  const { tenants } = useSelector(selectTenantsStatus, shallowEqual)
  const { businessStartTime, businessEndTime, businessDuration } = useBusinessTime()
  const { apiKey, magiQannealTenant, magiQannealLocation, isOptimization } = React.useMemo(() => {
    const application = tenants[0]?.applications.find(app => app.applicationId === MAGIQANNEAL_APPLICATION_ID)
    const targetOptimization = application?.options.relatedWorkspaceData?.find(
      rw => rw.relatedWorkspaceId === workspaceId
    )
    return {
      apiKey: application?.options.apiKey ?? '',
      magiQannealTenant: application?.options.tenant ?? '',
      magiQannealLocation: targetOptimization?.location ?? '',
      isOptimization: !!application && !!targetOptimization,
    }
  }, [tenants, workspaceId])

  const optimizationItems = React.useMemo(() => {
    return [
      {
        label: '配置結果を表示',
        onClick: () => {
          const now = moment().format('HH:mm')
          const workDate = encodeURIComponent(moment(work?.date && `${work.date} ${now}`).format())
          const server = process.env.REACT_APP_OPTIMIZATION_SERVER
          window.open(`${server}/${magiQannealTenant}/${magiQannealLocation}/board/?datetime=${workDate}`, '_blank')
        },
      },
      { label: '配置結果を作業計画に取込', onClick: () => setOpenImportOptimized(true) },
    ]
  }, [magiQannealTenant, magiQannealLocation, work])

  const correctionBusinessTime = React.useMemo(() => {
    const round = moment(businessEndTime, 'HH:mm').minutes() > 0 ? 1 : 0
    const start = `${businessStartTime.slice(0, 2)}:00`
    // 24:00 が 00:00 にならないように number で計算する
    const end = `${Number(businessEndTime.slice(0, 2)) + round}:00`
    return {
      start,
      end,
      duration: (moment(end, 'HH:mm').unix() - moment(start, 'HH:mm').unix()) / 900,
    }
  }, [businessEndTime, businessStartTime])

  const menuItems = React.useMemo(
    () =>
      _.chain(workspaces)
        .map(w => ({ type: w.workspaceId, label: w.name }))
        .sortBy('label')
        .value(),
    [workspaces]
  )

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaceId, workspaces])
  const isPast = React.useMemo(() => !!work && moment().isAfter(work.date, 'day'), [work])
  const isToday = React.useMemo(() => !!work && moment().isSame(work.date, 'day'), [work])
  const movePaths = React.useMemo(() => {
    const formattedDate = moment(work?.date).format('YYYY-MM-DD')
    const paths = [
      {
        label: 'ダッシュボード',
        onClick: () => navigate(`/dashboard/${workspaceId}?date=${formattedDate}`),
      },
    ]
    if (isToday) {
      paths.push({ label: '人員配置', onClick: () => navigate(`/assignment/${workspaceId}`) })
    }
    if (!isPast) {
      paths.push({
        label: 'シフト管理',
        onClick: () => navigate(`/shifts/${workspaceId}/${formattedDate}`),
      })
    }
    return paths
  }, [navigate, workspaceId, work?.date, isPast, isToday])

  const separateSchedules = React.useMemo(() => {
    // scheduleの開始時間とdurationを1時間単位に分解し、予測値を計算する
    const calculatedSeparateSchedule = editGroups
      .flatMap(g => g.workers)
      .flatMap(worker =>
        worker.schedules
          .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID) // シフトを除く
          .filter(
            s => scheduleTypes.find(scheduleType => scheduleType.scheduleTypeId === s.scheduleTypeId)?.dataConnection
          ) // 実績がないものをを除く
          .map(schedule => ({ schedule: schedule, performanceIndices: worker.performanceIndices }))
      )
      .map(({ schedule, performanceIndices }) => {
        const defaultPerformanceIndex = scheduleTypes.find(
          scheduleType => scheduleType.scheduleTypeId === schedule.scheduleTypeId
        )?.defaultPerformanceIndex
        const performanceIndex =
          performanceIndices.find(pi => pi.scheduleTypeId === schedule.scheduleTypeId)?.index ??
          defaultPerformanceIndex ??
          0
        const performanceRate =
          displayRate
            .find(pr => pr.scheduleTypeId === schedule.scheduleTypeId)
            ?.data!.map(data =>
              separateScheduleByQuarterHours(data.startTime, data.duration).map(s => ({
                // 生産性調整の計算のため、15分ごとに分解
                value: data.performanceRateValue,
                time: s.time,
              }))
            )
            .flatMap(data => data) || []

        // 生産性調整、人時生産性を含んだ予測値を計算
        return calcPlanValue(performanceRate, performanceIndex, schedule.startAt, schedule.duration).map(data => ({
          ...data,
          scheduleTypeId: schedule.scheduleTypeId,
        }))
      })
      .flatMap(schedules => schedules)
    const groupByScheduleTypeId = _.groupBy(calculatedSeparateSchedule, 'scheduleTypeId')

    // 同じ時間かつ同じscheduleTypeIdの予測値を合計する
    return _.keys(groupByScheduleTypeId)
      .map(scheduleTypeId => {
        const groupByTime = _.groupBy(groupByScheduleTypeId[scheduleTypeId], 'time')
        return _.keys(groupByTime).map(time => ({
          scheduleTypeId: Number(scheduleTypeId),
          time,
          planValue: _.sumBy(groupByTime[time], 'planValue'), // 同じ時間の予測値を合計する
        }))
      })
      .flatMap(data => data)
  }, [editGroups, scheduleTypes, displayRate])

  const createGraphRows = React.useCallback(
    (scheduleTypeId: number) =>
      (work &&
        work.hourlyRecords.map(hourlyRecord => {
          const actualValue =
            hourlyRecord.data.find(data => data.scheduleTypeId === scheduleTypeId)?.actualValue || null
          const planValue =
            separateSchedules.find(
              p =>
                p.scheduleTypeId === scheduleTypeId &&
                new Date(p.time).getTime() === new Date(hourlyRecord.time).getTime() //ミリ秒のフォーマットが異なっているため、Dateオブジェクトに変換し比較する
            )?.planValue || null
          return {
            time: hourlyRecord.time,
            planValue,
            actualValue,
          }
        })) ||
      [],
    [separateSchedules, work]
  )

  const workPlanCardItems = React.useMemo(() => {
    if (!work) {
      return []
    }

    return scheduleTypes.reduce((acc: WorkPlanCardItem[], cur) => {
      const graphRows = createGraphRows(cur.scheduleTypeId)
      const targetValue = work?.workPlan.find(w => w.scheduleTypeId === cur.scheduleTypeId)?.targetValue || 0
      const planValue = _.sumBy(
        separateSchedules.filter(schedule => schedule.scheduleTypeId === cur.scheduleTypeId),
        'planValue'
      )
      if (cur.dataConnection) {
        acc.push({
          label: cur.name,
          color: cur.color,
          unit: cur.unit,
          targetValue,
          planValue,
          graphRows,
          defaultPerformanceIndex: cur.defaultPerformanceIndex ?? 0,
        })
      }
      return acc
    }, [])
  }, [work, scheduleTypes, separateSchedules, createGraphRows])

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    const isSamePlanValue = work?.workPlan.every(
      wp =>
        Math.floor(
          _.sumBy(
            separateSchedulesBeforeSaving.current.filter(schedule => schedule.scheduleTypeId === wp.scheduleTypeId),
            'planValue'
          )
        ) === Math.floor(wp.planValue)
    )
    if (errorMessage === '') {
      if (scheduleTruncated || !isSamePlanValue) {
        dispatch(
          showWarning({
            warningMessage:
              '正常に処理されました。シフト情報が更新されたため、作業計画に変更が加えられている場合があります。',
          })
        )
        // workの更新で、デフォルトの人時生産性のみ更新されないため、レスポンスと一致しない場合に、scheduleTypeListを更新する
        !isSamePlanValue && dispatch(getScheduleTypeList(workspaceId))
      } else {
        dispatch(showSuccess())
      }
    } else {
      dispatch(showError())
      dispatch(clearErrorMessage())
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, scheduleTruncated, work, separateSchedules, workspaceId])

  const [isGetWorks, setIsGetWorks] = React.useState(false)
  const [isClickedScheduleTypeSelectorButton, setIsClickedScheduleTypeSelectorButton] = React.useState(false)

  React.useEffect(() => {
    if (!work || !isClickedScheduleTypeSelectorButton || isGetWorks) {
      return
    }
    // 計画推移グラフの比較対象を表示するためのworkを取得
    workspaces.forEach(w => {
      dispatch(getWorkForCompare(w.workspaceId, work.date))
    })
    setIsGetWorks(true)
  }, [work, isGetWorks, workspaces, dispatch, isClickedScheduleTypeSelectorButton])

  const scheduleTypeGroups = React.useMemo(() => {
    return workspaces
      .map<ScheduleTypeGroup>(w => {
        const scheduleTypeItems = w.scheduleTypes
          .filter(scheduleType => scheduleType.dataConnection)
          .map(scheduleType => ({
            scheduleTypeId: scheduleType.scheduleTypeId,
            scheduleTypeName: scheduleType.name,
            scheduleTypeColor: scheduleType.color,
          }))
        return {
          workspaceId: w.workspaceId,
          workspaceName: w.name,
          items: scheduleTypeItems,
        }
      })
      .filter(group => group.items.length > 0)
  }, [workspaces])

  const compareGraphs = React.useMemo(() => {
    const rows = worksToCompare.flatMap(w => {
      if (!w.hourlyRecords) {
        return []
      }
      return w.hourlyRecords.flatMap(hourlyRecord =>
        hourlyRecord.data.map(data => ({
          scheduleTypeId: data.scheduleTypeId,
          time: hourlyRecord.time,
          planValue: data.planValue,
          actualValue: data.actualValue,
        }))
      )
    })
    return workspaces.flatMap(w =>
      w.scheduleTypes.map<CompareGraph>(scheduleType => {
        const graphRows = scheduleTypes.some(s => s.scheduleTypeId === scheduleType.scheduleTypeId)
          ? createGraphRows(scheduleType.scheduleTypeId)
          : rows
              .filter(row => row.scheduleTypeId === scheduleType.scheduleTypeId)
              .map(row => ({
                time: row.time,
                planValue: row.planValue,
                actualValue: row.actualValue,
              }))
        return {
          scheduleTypeId: scheduleType.scheduleTypeId,
          scheduleTypeName: scheduleType.name,
          scheduleTypeColor: scheduleType.color,
          unit: scheduleType.unit,
          workspaceName: w.name,
          graphRows,
        }
      })
    )
  }, [worksToCompare, workspaces, scheduleTypes, createGraphRows])

  React.useEffect(() => {
    if (!work) {
      return
    }
    // depsにeditGroupsを追加しないために、setEditGroups内の関数で記載する
    setEditGroups(prev => {
      const editWorkers = prev.flatMap(g => g.workers)
      const groups: EditGroupsType[] = work.groups
        .filter((g: GroupResponse) => g.workers.length > 0)
        .map((g: GroupResponse) => {
          const workers = g.workers.map(w => {
            const workerSchedules = getEditSchedules(w.schedules).map(s => ({
              ...s,
              editable: true,
            }))
            const visible = editWorkers.some(editWorker => editWorker.workerId === w.workerId)
              ? editWorkers.find(editWorker => editWorker.workerId === w.workerId)!.visible
              : true
            return { ...w, schedules: workerSchedules, visible }
          })
          const schedules = getEditSchedules(g.schedules).map(s => ({
            ...s,
            editable: true,
          }))
          return {
            groupId: g.groupId,
            name: g.name ?? '未所属',
            supportedWorkspaceId: g.supportedWorkspaceId,
            supportedWorkspaceName: g.supportedWorkspaceName,
            schedules,
            workers,
          }
        })
      setInitGroups(groups)
      return groups
    })
  }, [work])

  const schedulesInWork = React.useMemo(() => {
    if (!work) {
      return []
    }
    return work.groups.flatMap(g => g.workers).flatMap(w => w.schedules)
  }, [work])

  const onSuccessShiftAdjustment = () => {
    setOpenShiftAdjustment(false)
    dispatch(showSuccess())
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWork(workspaceId, workId))
  }

  const onErrorShiftAdjustment = () => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWork(workspaceId, workId))
  }

  const onTargetValuesUpdateSuccess = () => {
    setOpenTargetValuesUpdate(false)
    dispatch(showSuccess())
  }

  const selector = () => {
    const menu = menuItems.find(item => item.type === workspaceId)
    return menu?.label || ''
  }

  const onNavMenuClick = (menu: string) => navigate(`/schedules/${menu}`)

  const unchanged = React.useMemo(
    () => _.isEqual(initGroups, editGroups) && _.isEqual(initDisplayRate, displayRate),
    [initGroups, editGroups, initDisplayRate, displayRate]
  )

  const disabled = React.useMemo(() => {
    if (user.role === Role.ProcessAdmin && !workspace?.memberIds.includes(user.userId)) {
      return true
    }
    // 作業(Schedule)が重なった状態のときは保存ボタンを無効にする
    const isOverlappedSchedule = editGroups.some(editGroup => {
      return (
        hasUnselectedSchedule(editGroup.schedules) ||
        editGroup.workers.some(worker => hasUnselectedSchedule(worker.schedules)) ||
        hasOverlappedSchedule(editGroup.schedules, false) ||
        editGroup.workers.some(worker => hasOverlappedSchedule(worker.schedules, false))
      )
    })
    const isOverlappedPerformanceRate = displayRate.some(performanceRate =>
      hasOverlappedPerformanceRate(performanceRate.data)
    )
    return isOverlappedSchedule || isOverlappedPerformanceRate
  }, [user, workspace, editGroups, displayRate])

  const onCancel = () => {
    setSelectedWorker([])
    setSelectedSchedules([])
    setEditGroups(initGroups)
    setDisplayRate(initDisplayRate)
    setShouldResetFilters(true)
  }

  React.useEffect(() => {
    if (!scheduleTypes) {
      return
    }
    const data = scheduleTypes
      .filter(s => s.dataConnection)
      .map(s => ({
        name: s.name,
        scheduleTypeId: s.scheduleTypeId,
        data: work?.performanceRates.find(pr => pr.scheduleTypeId === s.scheduleTypeId)?.data || [],
      }))

    setDisplayRate(data)
    setInitDisplayRate(data)
  }, [work, scheduleTypes])

  const createUpdatePerformanceRatesData = React.useCallback(() => {
    const performanceRates = displayRate.map(rate => {
      const initTargetRate = initDisplayRate.find(d => d.scheduleTypeId === rate.scheduleTypeId)
      const newPerformanceRate =
        initTargetRate?.data
          .filter(d => !_.includes(_.map(rate.data, 'performanceRateId'), d.performanceRateId))
          .map(d => ({ ...d, performanceRateValue: null })) || []
      return { scheduleTypeId: rate.scheduleTypeId, data: [...rate.data, ...newPerformanceRate] }
    })
    return { performanceRates }
  }, [displayRate, initDisplayRate])

  const createUpdateSchedulesData = React.useCallback(() => {
    setSelectedWorker([])
    setSelectedSchedules([])
    const schedules = editGroups.flatMap(cur => {
      const initGroup = initGroups.find(ig => ig.name === cur.name && !_.isEqual(ig, cur))

      if (!initGroup) {
        return []
      }

      // worker schedules の追加or更新
      const updateWorker = cur.workers.flatMap(w =>
        w.schedules.reduce((sacc: UpdateWorkSchedule[], scur) => {
          const isUnChanged = initGroup.workers.some(
            init => init.workerId === w.workerId && init.schedules.some(s => _.isEqual(s, scur))
          )

          if (!isUnChanged) {
            sacc.push({
              scheduleId: !scur.scheduleId || scur.scheduleId < 1 ? null : scur.scheduleId,
              schedule: {
                scheduleTypeId: scur.scheduleTypeId,
                supportWorkspaceId: scur.supportWorkspaceId,
                startAt: scur.startAt,
                duration: scur.duration,
                workerId: w.workerId,
                groupId: null,
              },
            })
          }
          return sacc
        }, [])
      )

      // group schedules の削除データ
      const curWorkerScheduleIds = cur.workers.flatMap(w => w.schedules.map(s => s.scheduleId))
      const deleteWorkerSchedule: UpdateWorkSchedule[] = initGroup.workers
        .flatMap(init =>
          init.schedules.filter(s => !curWorkerScheduleIds.includes(s.scheduleId)).map(s => s.scheduleId)
        )
        .map(scheduleId => ({ scheduleId, schedule: null }))
      return _.concat(updateWorker, deleteWorkerSchedule)
    })
    const originalSchedules = getOriginalSchedulesFromWork(schedules, work)
    return { schedules, originalSchedules }
  }, [editGroups, initGroups, work])

  const onSubmit = () => {
    const workData = _.isEqual(initGroups, editGroups) ? undefined : createUpdateSchedulesData()

    const performanceRatesData = _.isEqual(initDisplayRate, displayRate)
      ? undefined
      : createUpdatePerformanceRatesData()

    separateSchedulesBeforeSaving.current = separateSchedules
    setSubmitted(true)
    setShouldResetFilters(true)
    dispatch(updatePerformanceRatesAndWork(workspaceId, workId, workData, performanceRatesData))
  }

  const assignedTableItems = React.useMemo((): TableItemType[] => {
    // editGroups から schedule 部分だけを抽出する
    const schedules = editGroups.reduce((acc: WorkPlanSchedulesType[], cur) => {
      const workerSchedules = cur.workers.map(worker => worker.schedules)
      return acc.concat(...workerSchedules)
    }, [])

    const base: ItemScheduleType[] = scheduleTypes.map(s => ({
      id: s.scheduleTypeId,
      name: s.name,
      color: s.color,
      counts: new Map(),
    }))

    if (schedules.length === 0) {
      return [{ id: workspaceId, name: workspace?.name || '', counts: new Map(), schedules: base }]
    }

    const splitTime = businessStartTime.split(':')
    const workStartTime = moment(schedules[0].startAt).hours(Number(splitTime[0])).minutes(Number(splitTime[1]))
    const list: ItemScheduleType[] = [...Array(businessDuration)].reduce(baseacc => {
      const itemSchedules = schedules.reduce<ItemScheduleType[]>((acc, schedule) => {
        if (
          !schedule.scheduleTypeId ||
          schedule.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID ||
          schedule.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID
        ) {
          return acc
        }
        const scheduleStart = moment(schedule.startAt)
        const scheduleEnd = moment(schedule.startAt).add(schedule.duration, 'seconds')
        if (workStartTime.isBetween(scheduleStart, scheduleEnd, 'minutes', '[)')) {
          const target = _.findIndex(acc, { id: schedule.scheduleTypeId })
          if (target > -1) {
            const hour = acc[target].counts.get(workStartTime.hour()) || 0
            acc[target].counts.set(workStartTime.hour(), hour + 0.25)
          }
        }
        return acc
      }, baseacc)

      workStartTime.add(15, 'minutes')
      return itemSchedules
    }, base)

    const counts: Map<number, number> = list.reduce((acc, cur) => {
      Array.from(cur.counts.entries()).forEach(([key, value]) => acc.set(key, (acc.get(key) || 0) + value))
      return acc
    }, new Map<number, number>())

    return [{ id: workspaceId, name: workspace?.name || '', counts, schedules: list }]
  }, [businessDuration, businessStartTime, editGroups, scheduleTypes, workspace?.name, workspaceId])

  // スクロール位置の復元
  const workTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = workTableElement.current
    if (current && (current.scrollTop !== workTablePosition.top || current.scrollLeft !== workTablePosition.left)) {
      workTableElement.current?.scrollTo({
        behavior: 'auto',
        top: workTablePosition.top || 0,
        left: workTablePosition.left,
      })
    }
  }, [viewWorkTable, workTablePosition])

  const handleWorkTableScroll = React.useMemo(
    () =>
      _.debounce(
        () =>
          setWorkTablePosition({
            top: workTableElement.current?.scrollTop,
            left: workTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setWorkTablePosition]
  )

  const numberTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = numberTableElement.current
    if (current && (current.scrollTop !== numberTablePosition.top || current.scrollLeft !== numberTablePosition.left)) {
      numberTableElement.current?.scrollTo({
        behavior: 'auto',
        top: numberTablePosition.top || 0,
        left: numberTablePosition.left,
      })
    }
  }, [viewWorkTable, numberTablePosition])

  const handleNumberTableScroll = React.useMemo(
    () =>
      _.debounce(
        () =>
          setNumberTablePosition({
            top: numberTableElement.current?.scrollTop,
            left: numberTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setNumberTablePosition]
  )

  const performanceRatesTableElement = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const current = performanceRatesTableElement.current
    if (
      current &&
      (current.scrollTop !== performanceRatesTablePosition.top ||
        current.scrollLeft !== performanceRatesTablePosition.left)
    ) {
      performanceRatesTableElement.current?.scrollTo({
        behavior: 'auto',
        top: performanceRatesTablePosition.top || 0,
        left: performanceRatesTablePosition.left,
      })
    }
  }, [viewWorkTable, performanceRatesTablePosition])

  const handlePerformanceRatesTableScroll = React.useMemo(
    () =>
      _.debounce(
        () =>
          setPerformanceRatesTablePosition({
            top: performanceRatesTableElement.current?.scrollTop,
            left: performanceRatesTableElement.current?.scrollLeft,
          }),
        200
      ),
    [setPerformanceRatesTablePosition]
  )

  const tableView = React.useMemo(() => {
    if (!work) {
      return <></>
    }
    switch (viewWorkTable) {
      case 'workTable':
        return (
          <AssignToWorkTableContext.Provider value={{ shiftKeyDown, selectedSchedules, setSelectedSchedules }}>
            <AssignToWorkTable
              workspaceId={workspaceId}
              date={work.date}
              isPast={isPast}
              editGroups={editGroups}
              setEditGroups={setEditGroups}
              selectedWorker={selectedWorker}
              setSelectedWorker={setSelectedWorker}
              openGroups={openGroups}
              setOpenGroups={setOpenGroups}
              divElement={workTableElement}
              onScroll={handleWorkTableScroll}
              className={isOpenFilter ? styles.openFilterTableHeight : styles.closeFilterTableHeight}
            ></AssignToWorkTable>
          </AssignToWorkTableContext.Provider>
        )
      case 'assignedNumberTable':
        return (
          <AssignedNumberTable
            businessStartTime={correctionBusinessTime.start}
            businessEndTime={correctionBusinessTime.end}
            items={assignedTableItems}
            divElement={numberTableElement}
            onScroll={handleNumberTableScroll}
          ></AssignedNumberTable>
        )
      case 'performanceRateTable':
        return (
          <PerformanceRates
            date={work.date}
            isPast={isPast}
            displayRateData={displayRate}
            setDisplayRateData={setDisplayRate}
            divElement={performanceRatesTableElement}
            onScroll={handlePerformanceRatesTableScroll}
          />
        )
      default:
        return <></>
    }
  }, [
    viewWorkTable,
    work,
    assignedTableItems,
    correctionBusinessTime,
    editGroups,
    handleNumberTableScroll,
    handleWorkTableScroll,
    handlePerformanceRatesTableScroll,
    isPast,
    openGroups,
    selectedSchedules,
    selectedWorker,
    shiftKeyDown,
    workspaceId,
    displayRate,
    isOpenFilter,
  ])

  return (
    <>
      <NavMenu type={workspaceId} items={menuItems} onNavMenuClick={onNavMenuClick} isOpenSidebar={isOpenSidebar}>
        <>
          <div className={styles.container}>
            <Button
              color="link"
              className="text-secondary ps-0 text-decoration-none"
              size="sm"
              onClick={() => navigate(`/schedules/${workspaceId}`)}
            >
              {'＜日付一覧へ'}
            </Button>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex">
                <DateChangeButton
                  date={work?.date || moment().format('YYYY-MM-DD')}
                  onChange={date => dispatch(getWorkByDate(workspaceId, moment(date).format('YYYY-MM-DD'), false))}
                  onSuccess={() => navigate(`/schedules/${workspaceId}/${works[0].workId}`)}
                  onError={() => dispatch(showError({ errorMessage: '指定日に勤務時間が設定されていません。' }))}
                  isWorkPlanView
                />
                <div className="font-x-large fw-bold ps-2">の作業計画</div>
                <div className="px-2 align-self-center">
                  <BadgeLabel label={selector()} />
                </div>
                <SidebarButton isOpenSidebar={isOpenSidebar} onClick={() => setOpenSidebar(!isOpenSidebar)} />
              </div>
              <div className="d-flex">
                {movePaths.length !== 0 && (
                  <MoveDropdown items={movePaths} name="work-plan-move-dropdown"></MoveDropdown>
                )}
                {!isPast && isOptimization && (
                  <DropdownButton
                    buttonLabel="最適配置"
                    onClickButton={() => setOpenOptimization(true)}
                    className="ms-2"
                    dropdownItems={optimizationItems}
                  />
                )}
                <CustomButton
                  outline
                  icon="edit"
                  disabled={!work}
                  className="ms-2"
                  onClick={() => setOpenTargetValuesUpdate(true)}
                  name="work-plan-target-settings"
                >
                  作業目標の設定
                </CustomButton>
              </div>
            </div>

            {work ? (
              <div className={styles.workPlanContainer}>
                <div className={`${styles.cardContainer} ${isOpenSidebar ? 'w-25' : styles.w20}`}>
                  {_.sortBy(workPlanCardItems, 'label').map((p, index) => (
                    <div className={styles.workPlanCard} key={`work-plan-card-div-${index}`}>
                      <WorkPlanCard
                        key={`work-plan-card-${index}`}
                        label={p.label}
                        color={p.color}
                        unit={p.unit}
                        targetValue={p.targetValue}
                        planValue={p.planValue}
                        date={work.date}
                        businessStartTime={businessStartTime}
                        businessEndTime={businessEndTime}
                        graphRows={p.graphRows}
                        scheduleTypeGroups={scheduleTypeGroups}
                        compareGraphs={compareGraphs}
                        defaultPerformanceIndex={p.defaultPerformanceIndex}
                        setIsClickedScheduleTypeSelectorButton={setIsClickedScheduleTypeSelectorButton}
                      />
                    </div>
                  ))}
                </div>

                <div className={isOpenSidebar ? 'w-75' : styles.w80}>
                  <Card className="pb-3">
                    <CardBody
                      className={`d-flex ${
                        viewWorkTable === 'workTable' ? 'justify-content-between' : 'justify-content-end'
                      }`}
                    >
                      {viewWorkTable === 'workTable' && (
                        <div>
                          <WorkPlanFilter
                            onChange={setEditGroups}
                            onOpenChange={setIsOpenFilter}
                            setShouldReset={setShouldResetFilters}
                            shouldReset={shouldResetFilters}
                          />
                        </div>
                      )}

                      <div className={`mt-1 d-flex align-items-start ${styles.iconButtonContainer}`}>
                        {viewWorkTable === 'workTable' && (
                          <div className="d-flex">
                            <IconButton
                              outline
                              icon="shift"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenShiftAdjustment(true)}
                              tooltipText="シフト調整"
                              name="shift-adjustment-icon-button"
                              height={30}
                            />
                            <IconButton
                              outline
                              icon="template"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenTemplate(true)}
                              tooltipText="予定テンプレート入力"
                              name="template-icon-button"
                              height={30}
                            />
                            <IconButton
                              outline
                              icon="delete"
                              size="sm"
                              className="pe-2"
                              disabled={selectedWorker.length === 0}
                              onClick={() => setOpenDeleteSchedules(true)}
                              tooltipText="予定一括削除"
                              name="schedule-bulk-delete-icon-button"
                              height={30}
                            />
                          </div>
                        )}
                        <ButtonGroup>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('workTable')}
                            active={viewWorkTable === 'workTable'}
                            name="work-table"
                          >
                            予定入力
                          </Button>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('performanceRateTable')}
                            active={viewWorkTable === 'performanceRateTable'}
                            name="performance-rate-table"
                          >
                            生産性調整
                          </Button>
                          <Button
                            className={styles.viewWorkButton}
                            outline
                            color="secondary"
                            onClick={() => setViewWorkTable('assignedNumberTable')}
                            active={viewWorkTable === 'assignedNumberTable'}
                            name="assigned-number-table"
                          >
                            配置人数一覧
                          </Button>
                        </ButtonGroup>
                      </div>
                    </CardBody>

                    <CardBody className="py-0">{tableView}</CardBody>
                  </Card>
                </div>
              </div>
            ) : (
              <div>nodata</div>
            )}
          </div>
          {work && !isPast && (
            <SubmitFooter
              onCancel={onCancel}
              onSubmit={onSubmit}
              cancelDisabled={unchanged}
              submitDisabled={unchanged || disabled}
              updatedBy={work?.updatedBy}
              updatedAt={work?.updatedAt}
              submitName="work-plan-submit"
            />
          )}
        </>
      </NavMenu>

      {work && (
        <TargetValuesUpdate
          isOpen={openTargetValuesUpdate}
          workspaceId={workspaceId}
          workId={work.workId}
          isPast={isPast}
          onSuccess={onTargetValuesUpdateSuccess}
          onCancel={() => setOpenTargetValuesUpdate(false)}
        />
      )}
      <OptimizationDialog
        apiKey={apiKey}
        magiQannealTenant={magiQannealTenant}
        magiQannealLocation={magiQannealLocation}
        isOpen={openOptimization}
        workspaceId={workspaceId}
        workId={workId}
        editGroups={editGroups}
        onCancel={() => setOpenOptimization(false)}
        workDate={work?.date}
      />
      <ImportOptimizedDialog
        apiKey={apiKey}
        magiQannealTenant={magiQannealTenant}
        magiQannealLocation={magiQannealLocation}
        isOpen={openImportOptimized}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        onCancel={() => setOpenImportOptimized(false)}
        workDate={work?.date}
      />
      <DeleteSchedules
        isOpen={openDeleteSchedules}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        workerIds={selectedWorker}
        workDate={work?.date}
        onCancel={() => setOpenDeleteSchedules(false)}
      />
      <ImportTemplateDialog
        isOpen={openTemplate}
        workspaceId={workspaceId}
        workerIds={selectedWorker}
        editGroups={editGroups}
        setEditGroups={setEditGroups}
        onSuccess={() => {
          setOpenTemplate(false)
          dispatch(showSuccess())
        }}
        onCancel={() => setOpenTemplate(false)}
      />
      <ShiftAdjustment
        isOpen={openShiftAdjustment}
        workspaceId={workspaceId}
        schedules={schedulesInWork}
        workerIds={selectedWorker}
        workDate={work?.date}
        onCancel={() => setOpenShiftAdjustment(false)}
        onSuccess={onSuccessShiftAdjustment}
        onError={onErrorShiftAdjustment}
      />
    </>
  )
}

export default WorkPlan
