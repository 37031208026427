import { createSlice } from '@reduxjs/toolkit'

import * as API from 'api/optimization'
import type { ScheduleTypeResponse } from 'api/schedule_types'
import { makeErrorMessage } from 'api/utils'
import type { WorkerResponse } from 'api/workers'
import type { WorkspaceResponse } from 'api/workspaces'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as Spinner from 'slices/spinnerSlice'
import type { AllGroupType } from 'slices/groupsSlice'

import type { PayloadAction, ThunkDispatch } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

type OptimizationState = {
  isRequesting: boolean
  errorMessage: string
  optimizationError: boolean
  data?: API.GetLocationConfigResponse
  optimizedSchedule?: API.MagiQannealScheduleResponse
}

const initialState: OptimizationState = {
  isRequesting: false,
  errorMessage: '',
  optimizationError: false,
  data: undefined,
  optimizedSchedule: undefined,
}

export const optimizationSlice = createSlice({
  name: 'optimization',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    clearError: state => {
      state.errorMessage = ''
      state.optimizationError = false
    },
    apiFailure: state => {
      state.isRequesting = false
      state.optimizationError = true
    },
    apiSuccess: state => {
      state.isRequesting = false
    },
    getDataAtSuccess: (state, action: PayloadAction<API.GetLocationConfigResponse>) => {
      state.isRequesting = false
      state.data = action.payload
    },
    getMagiQannealScheduleSuccess: (state, action: PayloadAction<API.MagiQannealScheduleResponse>) => {
      state.isRequesting = false
      state.optimizedSchedule = action.payload
    },
    healthcheckError: (state, action: PayloadAction<string>) => {
      state.isRequesting = false
      state.optimizationError = true
      state.errorMessage = action.payload
    },
  },
})

export const {
  startRequest,
  clearError,
  apiFailure,
  apiSuccess,
  getDataAtSuccess,
  getMagiQannealScheduleSuccess,
  healthcheckError,
} = optimizationSlice.actions

type OptimizationAction = {
  type: string
  payload?: string | NetworkErrorDialog.NetworkErrorPayload
}
type OptimizationError = {
  errorMessage: string
}
const handleErrorResponse = (
  res: AxiosError<OptimizationError>,
  dispatch: ThunkDispatch<RootState, unknown, OptimizationAction>
) => {
  const errorCode = makeErrorMessage(res)
  const errorMessage = res.response?.data?.errorMessage || '問題が発生しました。管理者にお問い合わせください。'
  dispatch(NetworkErrorDialog.open({ code: errorCode, errorMessage }))
  dispatch(apiFailure())
}

export const getLocationConfig =
  (apiKey: string, tenant: string, location: string, datetime: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getLocationConfig(apiKey, tenant, location, datetime)
      .then((res: API.GetLocationConfigResponse) => dispatch(getDataAtSuccess(res)))
      .catch((res: AxiosError<OptimizationError>) => handleErrorResponse(res, dispatch))
      .finally(() => dispatch(Spinner.stop()))
  }

export const createDataAt =
  (
    apiKey: string,
    tenant: string,
    location: string,
    datetime: string,
    planned: API.PlanType[],
    processed: API.PlanType[],
    predicted: API.PlanType[],
    attendance: API.V2AttendanceType[]
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      await API.createTargetValues(apiKey, tenant, location, { datetime, planned, processed, predicted })
      await API.createAttendance(apiKey, tenant, location, { datetime, attendance })
      dispatch(apiSuccess())
    } catch (error) {
      handleErrorResponse(error as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const getMagiQannealSchedule =
  (apiKey: string, tenant: string, location: string, date: string): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getMagiQannealSchedule(apiKey, tenant, location, date)
      .then((res: API.MagiQannealScheduleResponse) => dispatch(getMagiQannealScheduleSuccess(res)))
      .catch((res: AxiosError<OptimizationError>) => handleErrorResponse(res, dispatch))
      .finally(() => dispatch(Spinner.stop()))
  }

export const healthCheck =
  (apiKey: string, tenant: string, locations: string[]): AppThunk =>
  async dispatch => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())

    Promise.all(locations.map(location => API.getLocation(apiKey, tenant, location)))
      .then(() => dispatch(apiSuccess()))
      .catch((res: AxiosError<OptimizationError>) => {
        // healthcheck に失敗した場合は errorMessage にメッセージを設定
        const errorMessage = res.response?.data?.errorMessage || '問題が発生しました。管理者にお問い合わせください。'
        dispatch(healthcheckError(errorMessage))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createRoster =
  (
    apiKey: string,
    tenant: string,
    location: string,
    datetime: string,
    workspaces: WorkspaceResponse[],
    allGroups: AllGroupType[],
    workers: WorkerResponse[],
    allScheduleTypes: ScheduleTypeResponse[]
  ): AppThunk =>
  async dispatch => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const config = await API.getLocationConfig(apiKey, tenant, location, datetime)
      const targetWorkIds = config?.works.map(w => w.workId) ?? []
      const roster = workers.map(w => {
        // skillsは人事生産性の値を返す
        const skills = allScheduleTypes
          .filter(s => targetWorkIds.includes(s.scheduleTypeId))
          .map(s => {
            const performance = w.performanceIndices.find(p => p.scheduleTypeId === s.scheduleTypeId)
            return {
              workId: s.scheduleTypeId,
              skill: (performance?.index || s.defaultPerformanceIndex) ?? 0,
            }
          })
        const workspace = workspaces.find(ws => w.workspaceId === ws.workspaceId)
        const targetGroup = allGroups
          .find(g => g.workspaceId === w.workspaceId)
          ?.groups.find(g => g.groupId === w.groupId)
        // `${ワークスペース名}_${グループ名}` を返す
        // ワークスペース未所属の場合は null、グループ未所属の場合は`${ワークスペース名}_未所属` を返す
        const group = workspace ? `${workspace.name}_${targetGroup?.name ?? '未所属'}` : null

        // flagsはリーダーかどうかとスキルIDを返す
        const flags = []
        if (w.groupLeader) {
          flags.push('leader')
        }
        if (w.skillIds) {
          flags.push(...w.skillIds.map(String))
        }

        return {
          workerId: w.wmsMemberId,
          workerName: w.name,
          group,
          skills,
          flags: flags.length > 0 ? flags : undefined,
        }
      })
      await API.createRoster(apiKey, tenant, location, { datetime, roster })
      dispatch(apiSuccess())
    } catch (e) {
      handleErrorResponse(e as AxiosError<OptimizationError>, dispatch)
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectOptimizationStatus = (state: RootState) => ({ ...state.optimization })

export default optimizationSlice.reducer
