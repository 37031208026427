import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import type { WorkLiteResponse } from 'api/works_lite'

import { getScheduleTypeList, selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList, selectWorkspacesStatus } from 'slices/workspacesSlice'
import { getWorkLiteList, selectWorksLiteStatus } from 'slices/worksLiteSlice'

import type { BadgeItem, TableHeaderType, TableCellType } from 'components/common/types'
import { isReadOnlyWorkspace } from 'components/common/utils'
import { formatPositiveNumber, getStyledColorClass } from 'components/Dashboard/utils'

import PlanItem from './PlanItem'
import SchedulesCommon from './SchedulesCommon'

const Schedules: React.FC = () => {
  const params = useParams<'workspaceId' | 'workId'>()
  const workspaceId = React.useMemo(() => Number(params.workspaceId), [params])

  const [isPast, setIsPast] = React.useState(false)
  const [selectedBadges, setSelectedBadges] = React.useState<number[]>([])
  const [selectedMonth, setSelectedMonth] = React.useState(moment().format('YYYY-MM'))
  const [isMoreLoadButtonClicked, setIsMoreLoadButtonClicked] = React.useState(false)

  const dispatch = useDispatch()
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const { workspaces } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { works } = useSelector(selectWorksLiteStatus, shallowEqual)
  const { scheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getWorkspaceList())
  }, [dispatch])

  React.useEffect(() => {
    if (workspaceId) {
      dispatch(getScheduleTypeList(workspaceId))
    }
    setSelectedBadges([])
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    if (workspaceId) {
      const from = isPast
        ? moment(selectedMonth).startOf('month').format('YYYY-MM-DD') // 選択した月の初日
        : moment().format('YYYY-MM-DD') // 当日
      // 初回ロードは2週間分、それ以降は45日分
      const loadDays = isMoreLoadButtonClicked ? 44 : 13
      const to = isPast
        ? moment(selectedMonth).endOf('month').format('YYYY-MM-DD') // 選択した月の末日
        : moment().add(loadDays, 'day').format('YYYY-MM-DD')
      dispatch(getWorkLiteList(workspaceId, from, to))
    }
  }, [dispatch, workspaceId, isPast, isMoreLoadButtonClicked, selectedMonth])

  const workspace = React.useMemo(() => workspaces.find(w => w.workspaceId === workspaceId), [workspaces, workspaceId])
  const isReadOnly = React.useMemo(() => isReadOnlyWorkspace(user, workspace), [user, workspace])

  React.useEffect(() => {
    const allScheduleTypeIds = scheduleTypes.filter(type => type.dataConnection).map(type => type.scheduleTypeId)
    setSelectedBadges(allScheduleTypeIds)
  }, [scheduleTypes])

  const badges: BadgeItem[] = React.useMemo(
    () =>
      scheduleTypes
        .filter(type => type.dataConnection)
        .map(type => ({ color: type.color, key: type.scheduleTypeId, label: type.name })),
    [scheduleTypes]
  )

  const worksWithManHours = React.useMemo(() => {
    return works.map(work => {
      const totalManHour = selectedBadges.reduce((total, selected) => {
        const workplan = work.workPlan.find(plan => plan.scheduleTypeId === selected)
        const difference = workplan ? Math.floor(workplan.planValue - workplan.targetValue) : 0
        const scheduleType = scheduleTypes.find(type => type.scheduleTypeId === selected)
        const manHour = workplan ? _.round(difference / (scheduleType?.defaultPerformanceIndex || 1), 1) : 0
        return _.round(total + manHour, 1)
      }, 0)
      return { ...work, totalManHour }
    })
  }, [works, scheduleTypes, selectedBadges])

  const getScheduleTableCells = React.useCallback(
    (work: WorkLiteResponse) => {
      return selectedBadges.reduce((acc: TableCellType[], selected) => {
        const workplan = work.workPlan.find(plan => plan.scheduleTypeId === selected)
        const planValue = workplan ? Math.floor(workplan.planValue) : '-'
        const difference = workplan ? Math.floor(workplan.planValue - workplan.targetValue) : 0
        const scheduleType = scheduleTypes.find(type => type.scheduleTypeId === selected)
        const manHour = workplan ? _.round(difference / (scheduleType?.defaultPerformanceIndex || 1), 1) : 0

        acc.push({
          value: (
            <PlanItem
              workPlans={work.workPlan}
              disabled={isPast || isReadOnly}
              workspaceId={workspaceId}
              scheduleTypeId={selected}
              workId={work.workId}
            />
          ),
          className: 'p-0',
        })
        acc.push({ value: planValue.toLocaleString() })
        acc.push({
          value: difference === 0 ? '-' : `${formatPositiveNumber(difference)} (${formatPositiveNumber(manHour)}人時)`,
          className: getStyledColorClass(difference),
        })
        return acc
      }, [])
    },
    [isPast, isReadOnly, selectedBadges, workspaceId, scheduleTypes]
  )

  const tableData: TableCellType[][] = React.useMemo(() => {
    if (!workspaceId) {
      return []
    }

    return [...Array(45)].reduce((acc: TableCellType[][], _cur, index: number) => {
      const date = moment().add(index, 'day')
      const work = worksWithManHours.find(w => w.date === date.format('YYYY-MM-DD'))

      if (!work) {
        return acc
      }

      const data: TableCellType[] = [
        { value: date.format('YYYY/MM/DD(dddd)') },
        { value: work.workers || 0 },
        {
          value: `${formatPositiveNumber(work.totalManHour)}人時`,
          className: `${getStyledColorClass(work.totalManHour)} border-end`,
        },
      ]

      acc.push(data.concat(getScheduleTableCells(work)))

      return acc
    }, [])
  }, [workspaceId, worksWithManHours, getScheduleTableCells])

  const pastTableData: TableCellType[][] = React.useMemo(
    () =>
      _.sortBy(worksWithManHours, 'date')
        .reverse()
        .reduce((acc: TableCellType[][], cur) => {
          const past = moment().isAfter(cur.date, 'day')
          if (!past) {
            return acc
          }
          const totalManHour = cur.totalManHour
          const data: TableCellType[] = [
            { value: moment(cur.date).format('YYYY/MM/DD(dddd)') },
            { value: cur.workers || 0 },
            {
              value: `${formatPositiveNumber(totalManHour)}人時`,
              className: `${getStyledColorClass(totalManHour)} border-end`,
            },
          ]

          acc.push(data.concat(getScheduleTableCells(cur)))

          return acc
        }, []),
    [getScheduleTableCells, worksWithManHours]
  )

  const header: TableHeaderType[] = React.useMemo(() => {
    const tableHeader: TableHeaderType[] = [
      { value: '日付', width: '40%' },
      { value: 'メンバー数', width: '30%' },
      { value: '過不足人時', width: '30%', className: 'border-end' },
    ]

    return selectedBadges.reduce((acc: TableHeaderType[], key) => {
      const badge = badges.find(b => b.key === key)
      if (badge) {
        return acc.concat([
          { value: `目標 - ${badge.label}`, width: '180px' },
          { value: `計画 - ${badge.label}`, width: '180px' },
          { value: `差分 - ${badge.label}`, width: '180px' },
        ])
      }
      return acc
    }, tableHeader)
  }, [badges, selectedBadges])

  const onBadgesChange = (list: number[]) => setSelectedBadges(list)
  const onMonthChange = (month: string) => setSelectedMonth(month)

  return (
    <SchedulesCommon
      isPast={isPast}
      isReadOnly={isReadOnly}
      tableHeader={header}
      tableData={isPast ? pastTableData : tableData}
      badges={badges}
      selectedBadges={selectedBadges}
      selectedMonth={selectedMonth}
      onBadgesChange={onBadgesChange}
      setIsPast={setIsPast}
      onMonthChange={onMonthChange}
      isMoreLoadButtonClicked={isMoreLoadButtonClicked}
      setIsMoreLoadButtonClicked={setIsMoreLoadButtonClicked}
    />
  )
}

export default Schedules
