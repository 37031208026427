import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import * as React from 'react'
import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalBody, CardBody, CardText, Label, Col } from 'reactstrap'

import type { ScheduleResponse, UpdateShiftsType } from 'api/schedules'

import { clearErrorMessage, selectWorkersStatus, updateWorkerShifts } from 'slices/workersSlice'

import { TimeSelect, SelectBoxFormat, CustomModal } from 'components/common'
import { SHIFT_SCHEDULE_TYPE_ID, roundedMoment } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'

dayjs.extend(utcPlugin)

type Props = {
  isOpen: boolean
  workspaceId: number
  workerIds: number[]
  schedules: ScheduleResponse[]
  workDate?: string
  onCancel: () => void
  onSuccess: () => void
  onError: () => void
}

const ADJUST_START_AT_ID = 1
const ADJUST_END_AT_ID = 2
const FIFTEEN_MINUTES_IN_SECONDS = 900
const adjustPatternList = [
  { key: ADJUST_START_AT_ID, value: '開始時間を指定時間にする' },
  { key: ADJUST_END_AT_ID, value: '終了時間を指定時間にする' },
]

const ShiftAdjustment: React.FC<Props> = props => {
  const { isOpen, workspaceId, workerIds, onCancel, onSuccess, onError, schedules, workDate } = props
  const [submitted, setSubmitted] = React.useState(false)
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>()
  const [selectedAdjustPatternId, setSelectedAdjustPatternId] = React.useState<number>(ADJUST_END_AT_ID)
  const [selectedHour, setSelectedHour] = React.useState('00')
  const [selectedMinute, setSelectedMinute] = React.useState('00')

  const { isRequesting, errorMessage } = useSelector(selectWorkersStatus, shallowEqual)

  const dispatch = useDispatch()
  const { businessStartTime, businessEndTime } = useBusinessTime()

  React.useEffect(() => {
    setSelectedAdjustPatternId(ADJUST_END_AT_ID)
    const { hour, minute } = roundedMoment(true)
    setSelectedHour(hour)
    setSelectedMinute(minute)
  }, [isOpen])

  // 開始時間を調整する場合のupdateStartAt、updateDurationの計算ロジック
  const calculateInAdjustStartAt = React.useCallback(
    (schedule: ScheduleResponse) => {
      const diffStartAtAndSelectedTime =
        dayjs(schedule.startAt).unix() -
        dayjs(`${workDate} ${selectedHour}:${selectedMinute}`, 'YYYY-MM-DD HH:mm').utc().unix()

      const updateDuration = schedule.duration + diffStartAtAndSelectedTime
      if (updateDuration > 0) {
        // 指定した開始時刻が終了時刻よりも前の場合、開始時刻を指定時刻で上書き、durationを計算する
        const updateStartAt = dayjs(`${workDate} ${selectedHour}:${selectedMinute}`).utc().toISOString()
        return {
          updateStartAt,
          updateDuration,
        }
      }
      const updateStartAt = dayjs
        .unix(dayjs(schedule.startAt).unix() + schedule.duration - FIFTEEN_MINUTES_IN_SECONDS)
        .utc()
        .toISOString()
      // 指定した開始時刻が終了時刻よりも後の場合、終了時刻は変更せずに、15分のdurationを残す
      // ex:12:00〜15:00のシフトに対して開始時刻を16:00に設定した場合、14:45〜15:00とする
      return {
        updateStartAt,
        updateDuration: FIFTEEN_MINUTES_IN_SECONDS,
      }
    },
    [selectedHour, selectedMinute, workDate]
  )

  // 終了時間を調整する場合のupdateStartAt、updateDurationの計算ロジック
  const calculateInAdjustEndAt = React.useCallback(
    (schedule: ScheduleResponse) => {
      const diffEndAtAndSelectedTime =
        dayjs(schedule.startAt).unix() +
        schedule.duration -
        dayjs(`${workDate} ${selectedHour}:${selectedMinute}`, 'YYYY-MM-DD HH:mm').utc().unix()
      const updateDuration = schedule.duration - diffEndAtAndSelectedTime
      return updateDuration > 0
        ? // 指定した終了時刻が開始時刻よりも後の場合、開始時刻は変更せずに、指定時刻に合わせてdurationを加減する
          {
            updateStartAt: schedule.startAt,
            updateDuration,
          }
        : // 指定した終了時刻が開始時刻よりも前の場合、開始時刻は変更せずに、15分のdurationを残す
          // ex:12:00〜15:00のシフトに対して終了時刻を11:00に設定した場合、12:00〜12:15とする
          {
            updateStartAt: schedule.startAt,
            updateDuration: FIFTEEN_MINUTES_IN_SECONDS,
          }
    },
    [selectedHour, selectedMinute, workDate]
  )

  const calculateUpdateStartAtAndDuration = React.useCallback(
    (schedule: ScheduleResponse) =>
      selectedAdjustPatternId === ADJUST_START_AT_ID
        ? calculateInAdjustStartAt(schedule)
        : calculateInAdjustEndAt(schedule),
    [calculateInAdjustEndAt, calculateInAdjustStartAt, selectedAdjustPatternId]
  )

  const requestSchedules = React.useMemo(() => {
    // 対象となるスケジュール（画面で選択されている作業者 かつ シフトスケジュール）を抽出
    const targetSchedules = schedules.filter(
      s => workerIds.includes(s.workerId) && s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID
    )
    // 同一作業者内の最早・最遅スケジュールを変別するために並べ替える
    const sortedSchedules = _.orderBy(targetSchedules, ['workerId', 'startAt'])
    const requestBody = sortedSchedules.reduce(
      (sacc: UpdateShiftsType, scur, index) => {
        // 開始時刻を調整する場合は作業者内の最も早いスケジュールのみを、終了時刻を調整する場合は最も遅いスケジュールのみを更新する
        // sortedSchedulesの前後のworkerIdをみて最早・最遅を判断
        const needCalculate =
          (selectedAdjustPatternId === ADJUST_START_AT_ID && scur.workerId !== sortedSchedules[index - 1]?.workerId) ||
          (selectedAdjustPatternId === ADJUST_END_AT_ID && scur.workerId !== sortedSchedules[index + 1]?.workerId)

        const { updateStartAt, updateDuration } = needCalculate
          ? calculateUpdateStartAtAndDuration(scur)
          : { updateStartAt: scur.startAt, updateDuration: scur.duration }

        // リクエストボディの整形
        sacc.schedules.push({
          scheduleId: scur.scheduleId,
          schedule: {
            scheduleTypeId: scur.scheduleTypeId,
            supportWorkspaceId: scur.supportWorkspaceId,
            startAt: updateStartAt,
            duration: updateDuration,
            workerId: scur.workerId,
            groupId: null,
          },
        })
        sacc.originalSchedules.push(scur)
        return sacc
      },
      { schedules: [], originalSchedules: [] }
    )
    return requestBody
  }, [schedules, workerIds, selectedAdjustPatternId, calculateUpdateStartAtAndDuration])

  const onSubmit = () => {
    if (typeof workDate === 'undefined') {
      return
    }
    setSubmitted(true)
    dispatch(updateWorkerShifts(requestSchedules, workspaceId, workDate))
  }

  React.useEffect(() => {
    if (isRequesting || !submitted) {
      return
    }
    if (errorMessage === '') {
      setModalErrorMessage(undefined)
      onSuccess()
    } else {
      setModalErrorMessage(errorMessage)
      dispatch(clearErrorMessage)
      onError()
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, onSuccess, onError])

  return (
    <CustomModal
      isOpen={isOpen}
      title="シフト調整"
      approveLabel="保存"
      errorMessage={modalErrorMessage}
      onCancel={onCancel}
      onApprove={onSubmit}
      onHideNotification={() => setModalErrorMessage(undefined)}
    >
      <ModalBody className="font-small px-4">
        <CardBody>
          <CardText>
            選択されているメンバーのシフトを調整します。
            <br />
            <span className="text-danger">
              保存時に作業計画画面は自動更新されます。未保存の作業予定がある場合は、シフト調整をキャンセルして先に作業計画を保存してください。
            </span>
          </CardText>
        </CardBody>
        <CardBody className="pt-4">
          <SelectBoxFormat
            label="シフト調整"
            value={selectedAdjustPatternId?.toString()}
            size="middle"
            items={adjustPatternList}
            onChange={event => {
              setSelectedAdjustPatternId(Number(event.key))
            }}
          />
        </CardBody>
        <CardBody className="py-0">
          <div className="d-flex">
            <Label md={4}>指定時間</Label>
            <Col md={6}>
              <div style={{ paddingLeft: '0.6rem' }}>
                <TimeSelect
                  hour={selectedHour}
                  minute={selectedMinute}
                  label=""
                  start={businessStartTime}
                  end={businessEndTime}
                  onChange={(hour, minute) => {
                    setSelectedHour(hour)
                    setSelectedMinute(minute)
                  }}
                />
              </div>
            </Col>
          </div>
        </CardBody>
      </ModalBody>
    </CustomModal>
  )
}

export default ShiftAdjustment
